import * as React from 'react';

import Grid from '@mui/material/Grid';

import VenusFragment from './Fragment';
import Venus from './Venus';

class VenusBox extends React.Component {
    constructor(props) {
        super();
        this.globalelements = props.globalelements;
        this.element = props.element;
        this.width = props.width;
        this.grids = props.grids;
        this.height = props.height;
    }

    render() {
        if (this.element.poscondition || this.element.negcondition)
        {
            const cookieKeys = Venus.getCookie('flags').split(',');
            if (this.element.poscondition)
            {
                const conditions = this.element.poscondition.split(',');
                if (conditions.filter(n => !cookieKeys.includes(n)).length > 0) return;
            }
            if (this.element.negcondition)
            {
                const conditions = this.element.negcondition.split(',');
                if (conditions.filter(n => cookieKeys.includes(n)).length > 0) return;
            }
        }

        let halign = this.element.style ? this.element.style.halign : null;
        let valign = this.element.style ? this.element.style.valign : null;
        let backgroundImage = this.element.style ? this.element.style.backgroundImage : null;
        let backgroundPosition = this.element.style ? this.element.style.backgroundPosition : null;
        let backgroundRepeat = this.element.style ? this.element.style.backgroundRepeat : null;
        let backgroundColor = this.element.style ? this.element.style.backgroundColor : null;
        let backgroundSize = this.element.style ? this.element.style.backgroundSize : null;
        let border = this.element.style ? this.element.style.border : null;
        let padding = this.element.style ? this.element.style.padding : null;
        let rounded = this.element.style ? this.element.style.rounded : null;
        let sticky = this.element.style ? this.element.style.sticky : null;
        let fixed = this.element.style ? this.element.style.fixed : null;

        if (!border) border = [0, 0, 0, 0, ''];
        if (!padding) padding = [0, 0, 0, 0];

        let style = {};
        style.width = this.width + 'px';
        if (this.height > 0) style.height = this.height + 'px';
        if (halign === 'left') style.justifyContent = 'flex-start';
        if (halign === 'center') style.justifyContent = 'center';
        if (halign === 'right') style.justifyContent = 'flex-end';
        if (valign === 'top') style.alignItems = 'flex-start';
        if (valign === 'center') style.alignItems = 'center';
        if (valign === 'bottom') style.alignItems = 'flex-end';
        if (backgroundImage) style.backgroundImage = 'url("' + CSS.escape(backgroundImage) + '")';
        if (backgroundPosition) style.backgroundPosition = backgroundPosition;
        if (backgroundRepeat) style.backgroundRepeat = backgroundRepeat;
        if (backgroundColor) style.backgroundColor = backgroundColor;
        if (backgroundSize) style.backgroundSize = backgroundSize;
        if (border[0] > 0) style.borderTop = border[0] + 'px solid' + border[4];
        if (border[1] > 0) style.borderBottom = border[1] + 'px solid' + border[4];
        if (border[2] > 0) style.borderLeft = border[2] + 'px solid' + border[4];
        if (border[3] > 0) style.borderRight = border[3] + 'px solid' + border[4];
        if (padding[0] > 0) style.paddingTop = padding[0] + 'px';
        if (padding[1] > 0) style.paddingBottom = padding[1] + 'px';
        if (padding[2] > 0) style.paddingLeft = padding[2] + 'px';
        if (padding[3] > 0) style.paddingRight = padding[3] + 'px';
        if (rounded) style.borderRadius = rounded + 'px';
        if (sticky) {
            style.position = 'sticky';
            if (sticky.hasOwnProperty('top')) style.top = sticky.top + 'px';
            if (sticky.hasOwnProperty('bottom')) style.bottom = sticky.bottom + 'px';
        }
        if (fixed) {
            style.position = 'fixed';
            if (fixed.hasOwnProperty('top')) style.top = fixed.top + 'px';
            if (fixed.hasOwnProperty('bottom')) style.bottom = fixed.bottom + 'px';
        }
        return <Grid id={this.element.uuid} key={this.element.uuid} container item spacing={0} style={style}><VenusFragment globalelements={this.globalelements} uuid={this.element.uuid} elements={this.element.children ? this.element.children : []} width={this.width - border[2] - border[3] - padding[2] - padding[3]} grids={this.grids} variant={this.element.variant && this.element.variant.toLowerCase() === 'compact' ? 'compact' : 'normal'} direction={this.element.direction && this.element.direction.toLowerCase() === 'right' ? 'right' : 'left'} /></Grid>;
    }
}

export default VenusBox;