import PageBasic from './feature/page.basic/main';
import ScheduleBasic from './feature/schedule.basic/main';

export function loadFeatures() {
    return {
        page: { basic: PageBasic },
        schedule: { basic: ScheduleBasic }
    };
}

export function loadPlugins() {
    return {
    };
}