import * as React from 'react';

import Grid from '@mui/material/Grid';

import VenusFragment from './Fragment';
import { refresh } from '../main/App';

class Venus extends React.Component {
    constructor(props) {
        super();
        this.data = props.data;
        this.meta = props.meta;
        this.state = { width: document.documentElement.clientWidth, height: document.documentElement.clientHeight, scroll: window.scrollY };
        if (this.meta) {
            if (this.meta.redirect) {
                for (const language of navigator.languages) {
                    if (this.meta.redirect.location[language]) return this.redirect(this.meta.redirect.location[language]);
                }
                if (this.meta.redirect.loose) {
                    const looseLocation = {};
                    Object.keys(this.meta.redirect.location).forEach((key) => {
                        const looseKey = key.substring(0, 2);
                        if (!looseLocation[looseKey]) looseLocation[looseKey] = this.meta.redirect.location[key];
                    });
                    for (const language of navigator.languages) {
                        if (looseLocation[language]) return this.redirect(looseLocation[language]);
                    }
                }
                return this.redirect(this.meta.redirect.location[this.meta.redirect.default]);
            }
            if (this.meta.icon) {
                let link = document.querySelector("link[rel~='icon']");
                if (!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    document.head.appendChild(link);
                }
                link.href = this.meta.icon;
            }
            if (this.meta.title)
                document.title = this.meta.title;
            if (this.meta.background) {
                const body = document.getElementsByTagName('BODY')[0];
                if (this.meta.background.backgroundImage) body.style.backgroundImage = 'url("' + CSS.escape(this.meta.background.backgroundImage) + '")';
                if (this.meta.background.backgroundPosition) body.style.backgroundPosition = this.meta.background.backgroundPosition;
                if (this.meta.background.backgroundRepeat) body.style.backgroundRepeat = this.meta.background.backgroundRepeat;
                if (this.meta.background.backgroundColor) body.style.backgroundColor = this.meta.background.backgroundColor;
                if (this.meta.background.backgroundSize) body.style.backgroundSize = this.meta.background.backgroundSize;
            }
        }
        window.Venus = Venus;
    }

    redirect(location) {
        window.location.href = location;
    };

    updateDimensions = () => {
        this.setState({ width: document.documentElement.clientWidth, height: document.documentElement.clientHeight, scroll: window.scrollY });
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions, { passive: true });
        this.setState({ width: document.documentElement.clientWidth, height: document.documentElement.clientHeight });
        if (window.location.hash !== '') window.requestAnimationFrame(function () {
            document.getElementById(window.location.hash.substring(1)).scrollIntoView();
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    static refreshPage() {
        refresh();
    }

    static setFlag(flag) {
        const flags = Venus.getCookie('flags').split('');
        if (flags.includes(flag)) return;
        flags.push(flag);
        Venus.setCookie('flags', flags.join(','), 30);
    }

    static unsetFlag(flag) {
        const flags = Venus.getCookie('flags').split('');
        const index = flags.indexOf(flag);
        if (index < 0) return;
        Venus.setCookie('flags', flags.splice(index, 1).join(','), 30);
    }

    static setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    static getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return '';
    }

    static eraseCookie(name) {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    render() {
        document.getElementById('venus.style').innerHTML = this.meta.style ? this.meta.style : '';
        const pageWidth = this.meta.pageWidth && this.meta.pageWidth < this.state.width ? this.meta.pageWidth : this.state.width;
        const gridWidth = this.meta.gridWidth ? this.meta.gridWidth : 100;
        const grids = Math.floor(pageWidth / gridWidth);
        return <div align='center'><Grid container spacing={0} style={{ width: pageWidth }}><VenusFragment key={pageWidth} elements={[]} globalelements={this.data} width={pageWidth} uuid="" grids={grids} variant={this.meta.variant} direction={this.meta.direction} /></Grid></div>;
    }
}

export default Venus;