import * as React from 'react';

import { load_component } from '../main/App';

class VenusExtension extends React.Component {
    constructor(props) {
        super();
        this.element = props.element;
        this.width = props.width;
        this.height = props.height;
    }

    render() {
        return load_component(this.element.feature, this.element.path, this);
    }
}

export default VenusExtension;
