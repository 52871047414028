import PageLoader from './PageLoader';



class VenusLoader extends PageLoader {

    loadElement(content) {
        if (content.type === 'manifest') {
            this.meta.menifest = content.src;
            if (content.src.substring(0, 6) === '/file/') this.file.push(content.src.substring(6));
            return;
        }
        if (content.type === 'extscript') {
            this.html.push('<script src="' + content.src + '" defer></script>');
            return;
        }
        if (content.type === 'icon') {
            this.meta.icon = content.src;
            if (content.src.substring(0, 6) === '/file/') this.file.push(content.src.substring(6));
            return;
        }
        if (content.type === 'logo') {
            this.meta.logo = content.src;
            if (content.src.substring(0, 6) === '/file/') this.file.push(content.src.substring(6));
            return;
        }
        if (content.type === 'title') {
            this.meta.title = content.value + (this.meta.title ? (' - ' + this.meta.title) : '');
            this.html.push('<div id="venus.title">' + content.value + '</div>');
            return;
        }
        if (content.type === 'background') {
            this.meta.background = content.style;
            if (content.style.backgroundImage && content.style.backgroundImage.substring(0, 6) === '/file/') this.file.push(content.style.backgroundImage.substring(6));
            return;
        }
        if (content.type === 'pageWidth') {
            this.meta.pageWidth = content.value;
            return;
        }
        if (content.type === 'gridWidth') {
            this.meta.gridWidth = content.value;
            return;
        }
        if (content.type === 'direction') {
            this.meta.direction = content.direction;
            return;
        }
        if (content.type === 'variant') {
            this.meta.variant = content.variant;
            return;
        }
        if (content.type === 'style') {
            this.meta.style = (this.meta.style ? this.meta.style : '') + content.style;
            return;
        }
        if (content.type === 'redirect') {
            this.meta.redirect = content;
            Object.values(content.location).forEach((location) => {
                this.html.push('<a href="' + location + '">' + location + '</a>');
            });
            return;
        }
        if (content.type === 'extension') {
            if (content.html) this.html.push('<div id="venus.' + content.uuid + '">' + content.html + '</div>');
            return;
        }
        if (content.type === 'box') {
            if (content.backgroundImage && content.backgroundImage.substring(0, 6) === '/file/') this.file.push(content.backgroundImage.substring(6));
            for (const subcontent in content.children) this.loadElement(subcontent);
            return;
        }
        if (content.type === 'image') {
            const code = '<img src="' + content.src + '" alt="' + (content.alt ? content.alt : '') + '"/>';
            this.html.push(content.href ? ('<a href="' + content.href + '">' + code + '</a>') : code);
            if (content.src.substring(0, 6) === '/file/') this.file.push(content.src.substring(6));
            return;
        }
        if (content.type === 'button') {
            if (content.operation === 'link') this.html.push('<a href="' + content.code + '">' + content.name + '</a>');
            return;
        }
        if (content.type === 'menu') {
            this.html.push(this.getMenuContent(content.data));
            return;
        }
        if (content.type === 'text') {
            this.html.push('<div id="venus.' + content.uuid + '">' + content.html + '</div>');
            if (!this.inpage) delete content.html;
            return;
        }
        if (content.type === 'script') {
            return;
        }
    }

    getMenuContent(menu) {
        const html = [];
        for (const item in menu) {
            if (item.children) {
                html.push('<div>' + item.name + '</div>');
                html.push(this.getMenuContent(item.children));
            }
            else if (item.operation === 'link') {
                html.push('<a href="' + item.code + '">' + item.name + '</a>');
            }
        }
        return html.join('');
    }
}



export default VenusLoader;
