import * as React from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { load } from '../main/App';

class VenusButton extends React.Component {
    constructor(props) {
        super();
        this.element = props.element;
        this.width = props.width;
        this.height = props.height;
    }

    checkPage(link) {
        return link.search(new RegExp('^/[A-Za-z0-9_-]+.html$')) >= 0
    }

    render() {
        let variant = this.element.style ? this.element.style.variant : null;
        let halign = this.element.style ? this.element.style.halign : null;
        let valign = this.element.style ? this.element.style.valign : null;
        let padding = this.element.style ? this.element.style.padding : null;
        let spacing = this.element.style ? this.element.style.spacing : null;
        let size = this.element.style ? this.element.style.size : null;
        let font = this.element.style ? this.element.style.font : null;
        let bold = this.element.style ? this.element.style.bold : null;
        let italic = this.element.style ? this.element.style.italic : null;
        let radius = this.element.style ? this.element.style.radius : null;
        let color = this.element.style ? this.element.style.color : null;
        let buttonWidth = this.element.style ? this.element.style.buttonWidth : null;
        let buttonHeight = this.element.style ? this.element.style.buttonHeight : null;

        if (!padding) padding = [0, 0, 0, 0];
        if (!color) color = ['#000000', '#000000', '#FFFFFF', '#000000', '#000000', '#FFFFFF'];

        let style = {};
        let bstyle = {textTransform: 'none'};
        style.width = this.width + 'px';
        if (this.height > 0) style.height = this.height + 'px';
        if (halign === 'left') style.justifyContent = 'flex-start';
        if (halign === 'center') style.justifyContent = 'center';
        if (halign === 'right') style.justifyContent = 'flex-end';
        if (valign === 'top') style.alignItems = 'flex-start';
        if (valign === 'center') style.alignItems = 'center';
        if (valign === 'bottom') style.alignItems = 'flex-end';
        if (padding[0] > 0) style.paddingTop = padding[0] + 'px';
        if (padding[1] > 0) style.paddingBottom = padding[1] + 'px';
        if (padding[2] > 0) style.paddingLeft = padding[2] + 'px';
        if (padding[3] > 0) style.paddingRight = padding[3] + 'px';
        if (buttonWidth) bstyle.width = buttonWidth + 'px';
        if (buttonHeight) bstyle.height = buttonHeight + 'px';
        if (size) bstyle.fontSize = size + 'px';
        if (color) bstyle.color = color;
        if (font) bstyle.fontFamily = font;
        if (bold) bstyle.fontWeight = 'bold';
        if (italic) bstyle.fontStyle = 'italic';

        let transparent = variant.toLowerCase() === 'transparent';
        if (transparent) bstyle.backgroundColor = 'transparent';
        let sx = {
            '& .MuiButton-root': {borderRadius: radius, color: color[0], borderColor: color[1], backgroundColor: color[2]},
            '& .MuiButton-root:hover': {borderRadius: radius, color: color[3], borderColor: color[4], backgroundColor: color[5]}
        };
        let button = <Button disableElevation size={spacing} key={this.element.uuid} style={bstyle} onClick={() => { this.triggleElement(this.element); }}>{this.element.name}</Button>;
        if (!transparent)
            button = <ButtonGroup variant={variant} size={spacing} sx={sx} disableElevation>{button}</ButtonGroup>;
        return <Grid id={this.element.uuid} key={this.element.uuid} container item style={style}>{button}</Grid>;
    }

    triggleElement(element) {
        if (element.operation === 'link') {
            if (this.checkPage(element.code))
                load(element.code.slice(0, -5));
            else
                window.location.href = element.code;
        }
        else
            eval(element.code);
    }
}

export default VenusButton;