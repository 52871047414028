import * as React from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { SimpleTreeView, } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import AbstractPage from '../../main/AbstractPage';
import { TextInput, AbstractForm, DateInputExt } from '../../main/AbstractForm';
import { load_component, refresh } from '../../main/App';
import { checkEmail } from '../../main/Utility';

import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";



dayjs.extend(utc);
dayjs.extend(timezone);



class Schedule extends AbstractPage {

    static cookie = 0;
    static schedule = [];

    constructor(props) {
        let catalogID = parseInt(props.path);
        if (isNaN(catalogID) || catalogID < 0) catalogID = 0;
        super('/feature/schedule.basic?ID=' + catalogID);
        this.catalogID = catalogID;

        this.state = { mode: 0, title: '' };
        this.reference = props.reference;
        this.reference.schedule = this;

        this.mode = this.reference.element.display === "page" ? "p" : "d";
        this.width = this.reference.width;
        this.height = this.reference.height;

        this.uuid = this.reference.element.uuid;
        this.description = this.reference.element.description;
        this.lan = this.reference.element.lan;
        this.start = this.reference.element.start;
        if (isNaN(this.start) || this.start > 3 || this.start < 0) this.start = 0;
        this.chart = this.reference.element.chart;
        this.stage = this.start;

        const value = Schedule.getSechedule(this.reference.element.uuid);
        this.description.forEach(line => {
            line.forEach(element => {
                if (!value.description.hasOwnProperty(element.name)) value.description[element.name] = '';
            });
        });
        Schedule.setSchedule(this.reference.element.uuid, value);

        const confirmation = this.lan.confirmation;
        this.form = new AbstractForm('/feature/schedule.basic?ID=' + catalogID, 'post', value);
        this.form.obj = this;
        this.form.result = function (result, info) {
            window.alert(confirmation);
            this.obj.stage = this.obj.start;
            this.obj.forceUpdate();
        }

        window.Schedule = Schedule;
    }

    result(result, info) {
        this.info = info;
        this.forceUpdate();
    }

    draw() {
        if (this.resultCode === -2) return this.mode === 'p' ? load_component('login') : refresh();
        return this.mode === 'd' ? this.drawDiag() : this.drawForm();
    }

    drawDiag() { }

    drawForm() {
        const [main, state, title] = this.drawStage();
        return main;
    }

    dayObj(timestamp = null) {
        const obj = timestamp ? dayjs.unix(timestamp) : dayjs();
        if (!this.info || this.info.timezone === '')
            return obj.tz(dayjs.tz.guess());
        else
            return obj.tz(this.info.timezone);
    }

    getTimeStr(time, str) {
        return str.replace('%A%', time.year()).replace('%B%', ('0' + (time.month() + 1)).slice(-2)).replace('%C%', ('0' + time.date()).slice(-2))
            .replace('%D%', ('0' + time.hour()).slice(-2)).replace('%E%', ('0' + time.minute()).slice(-2)).replace('%F%', ('0' + time.second()).slice(-2)).replace('%G%', (time.hour() + 24) % 12 || 12).replace('%H%', time.hour() < 12 ? 'am' : 'pm');
    }

    getPriceStr(priceVal) {
        const decPart = priceVal % 1000;
        const intPart = priceVal - decPart;
        return this.lan.priceformat.replace('%A%', intPart / 1000).replace('%B%', ('000' + decPart).substring(0, 2));
    }

    getDurationStr(duration) {
        return (this.lan.durationformat).replace("%A%", duration);
    }

    drawButtonGroup(button, profile) {
        return <ButtonGroup sx={{
            '& .MuiButton-root': { color: profile.color, backgroundColor: profile.backgroundColor, borderColor: profile.color },
            '& .MuiButton-root:hover': { color: profile.color, backgroundColor: profile.hoverBackgroundColor, borderColor: profile.color }
        }}>{button}</ButtonGroup>;
    }

    drawStepper() {
        const color = this.reference.element.color;
        return <Stepper activeStep={this.stage} alternativeLabel sx={{
            '& .MuiStepLabel-root .MuiStepIcon-root': { color: this.reference.element.disabledColor.borderColor },
            '& .MuiStepLabel-root .Mui-active': { color: this.reference.element.mainColor.borderColor },
            '& .MuiStepLabel-root .Mui-completed': { color: this.reference.element.secondaryColor.borderColor },
            '& .MuiStepLabel-root .MuiStepIcon-root .MuiStepIcon-text': { fill: this.reference.element.disabledColor.color },
            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': { fill: this.reference.element.mainColor.color },
            '& .Mui-completed .MuiStepConnector-line': { borderColor: this.reference.element.secondaryColor.borderColor },
            '& .Mui-active .MuiStepConnector-line': { borderColor: this.reference.element.disabledColor.borderColor },
            '& .MuiStepConnector-line': { borderColor: this.reference.element.disabledColor.borderColor }
        }} style={{ paddingBottom: "30px" }}>{["step_service", "step_time", "step_message", "step_confirmation"].map((label, i) => {
            if (i < this.start) return null;
            return <Step key={label}>
                <StepLabel style={{ cursor: (i === this.start || this.isStageFinished(i - 1)) ? "pointer" : "" }} onClick={(e) => { if (i === this.start || this.isStageFinished(i - 1)) { this.saveStage(); this.stage = i; this.forceUpdate(); } }}>{this.lan.step[label]}</StepLabel>
            </Step>;
        })}</Stepper>;
    }

    drawFooter() {
        return <Box display="flex" justifyContent="flex-end" style={{ paddingTop: "30px", width: "100%" }}>
            {this.stage > this.start ? this.drawButtonGroup(<Button color="primary" variant="contained" onClick={() => { this.stage -= 1; this.forceUpdate(); }}>{this.lan.back}</Button>, this.reference.element.secondaryColor) : null} &nbsp;&nbsp; {this.drawButtonGroup(<Button color="primary" variant="contained" onClick={() => { if (!this.saveStage()) return; if (!this.isStageFinished(this.stage)) return; if (this.stage === 3) { this.form.value = Schedule.getSechedule(this.uuid); this.form.submit(); } else { this.stage += 1; this.forceUpdate(); } }}>{this.stage === 3 ? this.lan.submit : this.lan.next}</Button>, this.isStageFinished(this.stage) || this.stage === 2 ? this.reference.element.mainColor : this.reference.element.disabledColor)} &nbsp;&nbsp;
        </Box>;
    }

    drawStage() {
        let title = '';
        let main = null;
        let state = null;
        if (this.stage === 0) {
            main = this.drawService();
        }
        else if (this.stage === 1) {
            main = this.drawTime();
        }
        else if (this.stage === 2) {
            main = this.drawDescription();
        }
        else if (this.stage === 3) {
            main = this.drawSummary();
        }
        return [(<div style={{ width: this.width + 'px' }}>
            {this.drawStepper()}
            {main}
            {this.drawFooter()}
        </div>), state, title];
    }

    isStageFinished(i) {
        if (i === 0) {
            let sum = 0;
            Object.values(Schedule.getService(this.uuid)).forEach((v) => { sum += v; });
            return sum > 0;
        }
        if (i === 1) {
            const [slots, dslots] = this.checkAvailablility(-1, -1);
            return slots.includes(Schedule.getTime(this.uuid));
        }
        if (i === 2) return this.checkDescription(true);
        return true;
    }

    saveStage() {
        if (this.stage === 2) {
            Schedule.setDescription(this.uuid, this.form.value);
            if (!this.checkDescription(false)) return false;
            return true;
        }
        return true;
    }

    drawService() {
        let expanded = [];
        let selected = [];
        let sum = [0, 0, 0];
        return <Paper elevation={0} sx={{ minHeight: this.height + 'px', padding: '10px', backgroundColor: this.reference.element.backColor.backgroundColor }}><Grid container direction="column" justifyContent="space-between" alignItems="left"><Grid item align="left" xs={12}>
            <SimpleTreeView multiSelect defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} defaultExpandedItems={expanded}
                defaultSelectedItems={selected}>
                {this.info.catalogArr.map((item, i) => item.catalogID === this.catalogID ? this.drawCatalog(item, i, expanded, selected, sum) : null)}
                {this.info.articleArr.map((item, i) => item.catalogID === this.catalogID ? this.drawArticle(item, i, selected, sum) : null)}
            </SimpleTreeView>
        </Grid>{this.info.multiuser ? <Grid align="left"><Stack direction="row" spacing={1} justifyContent="flex-start" onClick={() => { this.form.value.users++; this.forceUpdate(); }} style={{ cursor: "pointer" }}><Typography>{this.lan.users}: </Typography><Typography>{this.form.value.users}</Typography><RemoveIcon fontSize="small" style={{ color: "#404040" }} onClick={(e) => { if (this.form.value.users > 1) { this.form.value.users--; this.forceUpdate(); } e.stopPropagation(); }} /></Stack></Grid> : null}<Grid item><Typography align="right"><b>{(this.info.price ? (this.lan.price + ': ' + this.getPriceStr(sum[1])) : '') + (this.info.price && this.info.time ? ' / ' : '') + (this.info.time ? (this.lan.time + ': ' + this.getDurationStr(sum[2] * this.info.slotLength)) : '')}</b></Typography></Grid></Grid></Paper>;
    }
    drawCatalog(item, i, expanded, selected, sum) {
        const currNum = sum[0];
        const catalogArr = this.info.catalogArr.map((subitem, ii) => (subitem.catalogID === item.ID ? this.drawCatalog(subitem, ii, expanded, selected, sum) : null));
        const articleArr = this.info.articleArr.map((subitem, ii) => (subitem.catalogID === item.ID ? this.drawArticle(subitem, ii, selected, sum) : null));
        if (catalogArr.filter(x => x).length === 0 && articleArr.filter(x => x).length === 0) return null;
        if (sum[0] > currNum) expanded.push('c' + i);
        return (
            <TreeItem key={'c' + i} itemId={'c' + i} sx={{
                '& > .MuiTreeItem-content': { backgroundColor: this.reference.element.backColor.backgroundColor + '!important' }
            }} label={<Box sx={{ display: 'flex', alignItems: 'center'}}>
                <Typography sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                    {item.name}
                </Typography>
            </Box>}>
                {catalogArr}
                {articleArr}
            </TreeItem>
        );
    }
    drawArticle(item, i, selected, sum) {
        const num = Schedule.getServiceItem(this.uuid, item.sortID);
        if (this.chart && num === 0) return null;
        if (num !== 0) selected.push('a' + i);
        sum[0] += num;
        sum[1] += (num * item.discountPrice);
        sum[2] += (num * item.weight);
        return (
            <TreeItem key={'a' + i} itemId={'a' + i} onClick={() => { this.addArticle(item.sortID); this.forceUpdate(); }} label={<Box sx={{ display: 'flex', alignItems: 'center', paddingTop: '5px' }}>
                <Typography sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                    {item.title} {(this.info.price || this.info.time ? '( ' : '') + (this.info.price ? this.getPriceStr(item.discountPrice) : '') + (this.info.price && this.info.time ? ' / ' : '') + (this.info.time ? this.getDurationStr(item.weight * this.info.slotLength) : '') + (this.info.price || this.info.time ? ' )' : '')}
                </Typography>
                {this.info.amount ? <Stack direction="row" spacing={1} justifyContent="flex-end" style={{ paddingLeft: '10px', width: '30%' }}><Typography style={{ color: num > 0 ? this.reference.element.focusColor.color : null }}>{num}</Typography><RemoveIcon fontSize="small" style={{ color: num > 0 ? this.reference.element.focusColor.color : null }} onClick={(e) => { this.removeArticle(item.sortID); this.forceUpdate(); e.stopPropagation(); }} /></Stack> : <Stack direction="row" spacing={1} justifyContent="flex-end" style={{ paddingLeft: '10px', width: '30%' }}>{num > 0 ? <CheckIcon fontSize="small" style={{ color: this.reference.element.focusColor.color }} /> : null}</Stack>}
            </Box>}
                sx={{
                    '& > .MuiTreeItem-content .MuiTreeItem-label': { color: num > 0 ? (this.reference.element.focusColor.color + '!important') : null },
                    '& > .MuiTreeItem-content': { backgroundColor: num > 0 ? (this.reference.element.focusColor.backgroundColor + '!important') : null }
                }}
            />
        );
    }
    addArticle(id) {
        let num = Schedule.getServiceItem(this.uuid, id) + 1;
        if ((!this.info.amount) && num > 1) num = 0;
        if ((!this.info.multielement) && num > 0) Schedule.setService(this.uuid, {});
        Schedule.setServiceItem(this.uuid, id, num);
    }
    removeArticle(id) {
        let num = Schedule.getServiceItem(this.uuid, id) - 1;
        if (num < 0) num = 0;
        Schedule.setServiceItem(this.uuid, id, num);
    }

    drawTime() {
        this.form.value._time = this.dayObj(Schedule.getTime(this.uuid));
        const [slots, dslots] = this.checkAvailablility(-1, -1);

        const d = this.form.value._time;
        const dayStart = d.hour(0).unix();
        const hasPrevious = Math.min.apply(Math, slots) < dayStart;
        const hasNext = Math.max.apply(Math, slots) >= dayStart + 86400;

        const slotList = slots.map(time => { if (time >= dayStart && time < dayStart + 86400) return this.drawButtonGroup(<Button onClick={() => { Schedule.setTime(this.uuid, time); this.forceUpdate(); }} style={{ margin: '5px' }}>{this.getTimeStr(this.dayObj(time), this.lan.shorttimeformat)}</Button>, Schedule.getTime(this.uuid) === time ? this.reference.element.mainColor : this.reference.element.secondaryColor); });
        const dslotList = dslots.map(time => { if (time >= dayStart && time < dayStart + 86400) return this.drawButtonGroup(<Button disabled style={{ margin: '5px' }}>{this.getTimeStr(this.dayObj(time), this.lan.shorttimeformat)}</Button>, this.reference.element.disabledColor); });
        return <Paper elevation={0} sx={{ minHeight: this.height + 'px', padding: '10px', backgroundColor: this.reference.element.backColor.backgroundColor }}><LocalizationProvider dateAdapter={AdapterDayjs}><Grid container>
            <Grid item xs={12}>
                <ArrowBackIosNewIcon fontSize="large" style={hasPrevious ? { cursor: 'pointer' } : { color: '#A0A0A0' }} onClick={() => { if (!hasPrevious) return; Schedule.setTime(this.uuid, Schedule.getTime(this.uuid) - 86400); this.forceUpdate(); }} /><DateInputExt form={this.form} id="_time" label={this.lan.date} onChange={() => { Schedule.setTime(this.uuid, this.form.value._time.unix()); this.forceUpdate(); }} fullwidth /><ArrowForwardIosIcon fontSize="large" style={hasNext ? { cursor: 'pointer' } : { color: '#A0A0A0' }} onClick={() => { if (!hasNext) return; Schedule.setTime(this.uuid, Schedule.getTime(this.uuid) + 86400); this.forceUpdate(); }} />
                <Box sx={{ height: '20px' }}></Box>
                {slotList.filter(v => v).length > 0 ? <Box sx={{ height: '20px' }}>___ {this.lan.available} ___</Box> : null}
                <Box sx={{ height: '20px' }}></Box>
                {slotList}
                <Box sx={{ height: '20px' }}></Box>
                {dslotList.filter(v => v).length > 0 ? <Box sx={{ height: '20px' }}>___ {this.lan.unavailable} ___</Box> : null}
                <Box sx={{ height: '20px' }}></Box>
                {dslotList}
                <Box sx={{ height: '20px' }}></Box>
            </Grid>
        </Grid></LocalizationProvider></Paper>;
    }
    checkAvailablility(start, end) {
        // Calculate time plans
        const timePlans = {};
        let length = 0;
        this.info.articleArr.forEach((elem) => {
            const num = Schedule.getServiceItem(this.uuid, elem.sortID);
            if (num === 0) return;
            const timePlanID = this.info.relationArr[elem.sortID];
            if (!timePlans[timePlanID]) timePlans[timePlanID] = 0;
            timePlans[timePlanID]++;
            length += elem.weight * num;
        });
        const availablePlans = [];
        Object.entries(timePlans).forEach(([key, value]) => {
            if (value === Object.keys(Schedule.getService(this.uuid)).length) availablePlans.push(parseInt(key));
        });

        // Calculate availabilities
        let availabilities = [];
        let inavailabilities = [];
        availablePlans.forEach(id => {
            let [a, i] = this.checkPlanAvailability(id, length, start, end);
            availabilities = availabilities.concat(a);
            inavailabilities = inavailabilities.concat(i);
        });
        return [availabilities, inavailabilities];
    }
    checkPlanAvailability(timePlanID, length, start, end) {
        // Get the information from time plan
        let info = null;
        this.info.timePlanArr.forEach((rsInfo, i) => {
            if (timePlanID !== rsInfo.ID) return;
            info = rsInfo;
        });
        if (!info) return [];

        // Preselect
        const currTime = Math.floor(Date.now() / 1000) + this.info.slotLength * 60;
        if (start < currTime + info.advanceTime * this.info.slotLength * 60) start = currTime + info.advanceTime * this.info.slotLength * 60;
        let count = 0;
        let preSelection = [];
        this.info.capacityArr.forEach((elem) => {
            count++;
            if (timePlanID !== elem.timePlanID) return;
            if (elem.time >= currTime) count++;
            if (count <= info.advanceSlot) return;
            if (elem.time < start || (end >= 0 && elem.time + this.info.slotLength * 60 > end)) return;
            if (elem.capacity < Schedule.getUsers(this.uuid)) return;
            preSelection.push(elem.time);
        });
        preSelection = [...new Set(preSelection)];

        // Select
        preSelection.sort(function (a, b) { return a - b });
        const selection = [];
        const dselection = [];
        for (let i = 0; i < preSelection.length; i++) {
            let available = length <= 1;
            if (i <= preSelection.length - length) {
                for (let j = i + 1; j < i + length; j++) {
                    if (preSelection[j] - preSelection[j - 1] !== this.info.slotLength * 60) break;
                    if (j == i + length - 1) available = true;
                }
            }
            if (available)
                selection.push(preSelection[i]);
            else
                dselection.push(preSelection[i]);
        }
        return [selection, dselection];
    }

    drawDescription() {
        const sx = {
            '& label': { color: '#808080' },
            '&:hover label': { color: '#A0A0A0' },
            "& label.Mui-focused": { color: '#808080' },
            "& .MuiInput-underline:before": { borderBottomColor: "#808080!important" },
            "& .MuiInput-underline:after": { borderBottomColor: "#A0A0A0!important" }
        };
        this.form.value = Schedule.getDescription(this.uuid);
        return <Paper elevation={0} sx={{ minHeight: this.height + 'px', padding: '10px', backgroundColor: this.reference.element.backColor.backgroundColor }}><Box component="form" noValidate autoComplete="off" sx={{ width: '100%' }}>
            {this.description.map(line => {
                return <><Stack direction="row" spacing={5}>{line.map(element => (
                    <TextInput form={this.form} sx={sx} id={element.name} label={this.lan.information[element.name]} fullwidth multiline={element.line && element.line > 1} rows={element.line} />
                ))}</Stack><Box sx={{ height: 20 }} /></>
            })}
        </Box></Paper>;
    }
    checkDescription(silent) {
        let isOk = true;
        this.description.forEach(line => {
            line.forEach(element => {
                if (!isOk) return;
                if (element.type && element.type === 'email') {
                    isOk = checkEmail(Schedule.getDescriptionField(this.uuid, element.name));
                    if (!silent) this.form.validity[element.name] = isOk ? '' : this.lan.information_error[element.name];
                }
                else {
                    isOk = (!element.min || Schedule.getDescriptionField(this.uuid, element.name).length >= element.min) && (!element.max || Schedule.getDescriptionField(this.uuid, element.name).length <= element.max);
                    if (!silent) this.form.validity[element.name] = isOk ? '' : this.lan.information_error[element.name];
                }
            });
        });
        if (!isOk && !silent) this.forceUpdate();
        return isOk;
    }

    drawSummary() {
        let sum = [0, 0];
        const articleList = this.info.articleArr.map(article => {
            const num = Schedule.getServiceItem(this.uuid, article.sortID);
            if (num === 0) return null;
            const price = article.discountPrice;
            const time = article.weight * this.info.slotLength;
            const pricesum = price * num;
            const timesum = time * num;
            sum[0] += pricesum;
            sum[1] += timesum;
            return <TableRow key={article.articleID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}><TableCell component="th" scope="row">{article.title}</TableCell>{this.info.amount ? <TableCell align="right">{num}</TableCell> : null}{this.info.price ? <TableCell align="right">{this.getPriceStr(pricesum)}</TableCell> : null}{this.info.time ? <TableCell align="right">{this.getDurationStr(timesum)}</TableCell> : null}</TableRow>
        });

        return <>
            <TableContainer><Table style={{backgroundColor: this.reference.element.backColor.backgroundColor }}>
                <TableHead>
                    <TableRow><TableCell colSpan={2} style={{color: this.reference.element.focusColor.color, backgroundColor: this.reference.element.focusColor.backgroundColor}}>{this.lan.summary}</TableCell></TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key="time" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}><TableCell component="th" scope="row">{this.lan.time}</TableCell><TableCell align="right">{this.getTimeStr(this.dayObj(Schedule.getTime(this.uuid)), this.lan.timeformat) + (this.info.time ? (' - ' + this.getTimeStr(this.dayObj(Schedule.getTime(this.uuid) + sum[1] * 60), this.lan.timeformat)) : '')}</TableCell></TableRow>
                    {this.info.price ? <TableRow key="price" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}><TableCell component="th" scope="row">{this.lan.price}</TableCell><TableCell align="right">{this.getPriceStr(sum[0])}</TableCell></TableRow> : null}
                    {this.description.map(line => {
                        return line.map(element => (
                            <TableRow key={element.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}><TableCell style={{ width: "30%" }} component="th" scope="row">{this.lan.information[element.name]}</TableCell><TableCell align="right" style={{ whiteSpace: "normal", wordWrap: "break-word" }}>{Schedule.getDescriptionField(this.uuid, element.name)}</TableCell></TableRow>
                        ))
                    })}
                </TableBody>
            </Table></TableContainer>
            <Box>&nbsp;</Box>
            <TableContainer><Table style={{backgroundColor: this.reference.element.backColor.backgroundColor }}>
                <TableHead>
                    <TableRow><TableCell colSpan={1 + (this.info.amount ? 1 : 0) + (this.info.price ? 1 : 0) + (this.info.time ? 1 : 0)} style={{color: this.reference.element.focusColor.color, backgroundColor: this.reference.element.focusColor.backgroundColor}}>{this.lan.detail}</TableCell></TableRow>
                </TableHead>
                <TableBody>
                    {articleList}
                </TableBody>
            </Table></TableContainer>
        </>;
    }

    static setCookie(name, value, days) {
        var expires = '';
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    }

    static getCookie(name) {
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(name + '=') === 0) return c.substring(name.length + 1, c.length);
        }
        return null;
    }

    static setCookieSave(cookie) {
        Schedule.cookie = cookie;
    }

    static getSechedule(uuid) {
        if (Schedule.cookie >= 0) {
            const str = Schedule.getCookie(uuid);
            if (str) return JSON.parse(decodeURIComponent(str));
        }
        else {
            const obj = Schedule.schedule[uuid];
            if (obj) return Schedule.schedule[uuid];
        }
        let timeAdj = (new Date()).getTimezoneOffset() * 60;
        return { users: 1, service: {}, time: Math.floor(Date.now() / 1000), description: {}, timeAdj: -timeAdj };
    }

    static setSchedule(uuid, schedule) {
        if (Schedule.cookie >= 0)
            Schedule.setCookie(uuid, encodeURIComponent(JSON.stringify(schedule)), Schedule.cookie);
        else
            Schedule.schedule[uuid] = schedule;
    }

    static getDescription(uuid) {
        return Schedule.getSechedule(uuid).description;
    }

    static setDescription(uuid, description) {
        const schedule = Schedule.getSechedule(uuid);
        schedule.description = description;
        Schedule.setSchedule(uuid, schedule);
    }

    static getDescriptionField(uuid, name) {
        const value = Schedule.getSechedule(uuid).description[name];
        return value ? value : '';
    }

    static setDescriptionField(uuid, name, value) {
        const schedule = Schedule.getSechedule(uuid);
        schedule.description[name] = value;
        Schedule.setSchedule(uuid, schedule);
    }

    static getTime(uuid) {
        return Schedule.getSechedule(uuid).time;
    }

    static setTime(uuid, time) {
        const schedule = Schedule.getSechedule(uuid);
        schedule.time = time;
        Schedule.setSchedule(uuid, schedule);
    }

    static getUsers(uuid) {
        return Schedule.getSechedule(uuid).users;
    }

    static setUsers(uuid, users) {
        const schedule = Schedule.getSechedule(uuid);
        schedule.users = users;
        Schedule.setSchedule(uuid, schedule);
    }

    static getService(uuid) {
        return Schedule.getSechedule(uuid).service;
    }

    static setService(uuid, service) {
        const schedule = Schedule.getSechedule(uuid);
        schedule.service = service;
        Schedule.setSchedule(uuid, schedule);
    }

    static getServiceItem(uuid, serviceId) {
        const service = Schedule.getSechedule(uuid).service[serviceId];
        return service ? service : 0;
    }

    static setServiceItem(uuid, serviceId, num) {
        const schedule = Schedule.getSechedule(uuid);
        schedule.service[serviceId] = num;
        Schedule.setSchedule(uuid, schedule);
    }
}



export default Schedule;