import * as React from 'react';

import { get_json, post_json, put_json, delete_json, post_file } from './App';



class AbstractHandler extends React.Component {
    constructor(path, method) {
        super();
        this.path = path;
        this.method = method;
    }

    handle(result, info) {
    }

    async submit(value = {}, path = '') {
        if (path === '') path = this.path;
        this.path = path;

        if (this.method === 'delete') {
            const params = decodeURIComponent((new URLSearchParams(value)).toString());
            path = path + (path.indexOf('?') < 0 ? '?' : '&') + params;
        }

        let result = null;
        if (this.method === 'get') result = await get_json(path);
        if (this.method === 'post') result = await post_json(path, value);
        if (this.method === 'put') result = await put_json(path, value);
        if (this.method === 'delete') result = await delete_json(path);
        if (this.method === 'file') result = await post_file(path, value.file, value);
        if (!result.hasOwnProperty('result')) {
            this.handle(-10, {});
            return;
        }
        this.handle(result.result, result.info);
    }

    render() {
        return null;
    }
}



export default AbstractHandler;