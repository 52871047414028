import * as React from 'react';

import AbstractPage from '../../main/AbstractPage';
import VenusLoader from '../../pageloader/VenusLoader'
import Venus from '../../venus/Venus';



class Page extends AbstractPage {

	constructor() {
		super('');
	}

    result(result, info) {
		const loader = new VenusLoader(true);
		const index = window.location.href.split('/').pop();
		loader.loadPage(info, index === '' ? 'index' : index.slice(0, -5));
		this.meta = loader.meta;
		this.data = loader.data;
		this.forceUpdate();
    }

    componentDidMount() {
        if (window.venusmeta)
		{
			if (!this.meta) {
				this.meta = JSON.parse(decodeURIComponent((window.venusmeta)));
				this.data = JSON.parse(decodeURIComponent(window.venusdata));
				this.forceUpdate();
			}
			return;
		}
		const index = window.location.href.split('/').pop();
		this.submit({}, '/feature/page.basic?index=' + (index === '' ? 'index' : index.slice(0, -5)));
    }

	render() {
		if (!this.meta) return;
		return <Venus data={this.data} meta={this.meta} />;
	}
}



export default Page;