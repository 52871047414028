import * as React from 'react';

import VenusBox from './Box';
import VenusImage from './Image';
import VenusButton from './Button';
import VenusMenu from './Menu';
import VenusText from './Text';
import VenusExtension from './Extension';
import VenusScript from './Script';

class VenusFragment extends React.Component {
    constructor(props) {
        super();
        this.elements = props.elements;
        this.globalelements = props.globalelements;
        this.width = props.width;
        this.grids = props.grids;
        this.variant = props.variant;
        this.direction = props.direction;
        this.gridwidth = props.width / props.grids;
        this.uuid = props.uuid;
    }

    drawElement(element, width, height, grids) {
        switch (element.type.toLowerCase()) {
            case 'box':
                return <VenusBox key={element.uuid} globalelements={this.globalelements} element={element} width={width} height={height} grids={grids} />;
            case 'image':
                return <VenusImage key={element.uuid} element={element} width={width} height={height} />;
            case 'button':
                return <VenusButton key={element.uuid} element={element} width={width} height={height} />;
            case 'menu':
                return <VenusMenu key={element.uuid} element={element} width={width} height={height} />;
            case 'text':
                return <VenusText key={element.uuid} element={element} width={width} height={height} />;
            case 'extension':
                return <VenusExtension key={element.uuid} feature={element.feature} element={element} width={width} height={height} />;
            case 'script':
                return <VenusScript key={element.uuid} element={element} width={width} height={height} />;
            default:
                return null;
        }
    }

    buildLine() {

        let important = false;
        this.line.forEach((element) => {
            if (!element.unimportant) important = true;
        });
        if (!important)
        {
            this.length = 0;
            this.line = [];
            this.weight = [];
            return [];
        }
        
        let flagArr = new Array(this.weight.length);
        let weightArr = new Array(this.weight.length);
        for (let i = 0; i < this.weight.length; i++) {
            flagArr[i] = 0;
            weightArr[i] = 0;
        }

        let adjusted = true;
        while (adjusted) {
            adjusted = false;

            let slots = this.grids;
            let grids = 0;
            for (let i = 0; i < this.weight.length; i++) {
                if (flagArr[i] > 0)
                    slots -= weightArr[i];
                else
                    grids += this.weight[i];
            }

            for (let i = 0; i < this.weight.length; i++) {
                if (flagArr[i] > 0) continue;
                const weight = Math.floor(slots / grids * this.weight[i]);
                if (weight > this.line[i].size[2] && this.line[i].size[2] > 0) {
                    flagArr[i] = 1;
                    weightArr[i] = this.line[i].size[2];
                    adjusted = true;
                }
                else if (weight < this.line[i].size[0]) {
                    flagArr[i] = 1;
                    weightArr[i] = this.line[i].size[0];
                    adjusted = true;
                }
                else {
                    weightArr[i] = weight;
                }
                slots -= weight;
                grids -= this.weight[i];
            }
        }

        if (this.direction && this.direction.toLowerCase() === 'right') {
            this.line = this.line.reverse();
            weightArr = weightArr.reverse();
        }

        let length = 0;
        let pixLength = 0;
        const result = this.line.map((element, i) => {
            const width = weightArr[i];
            const height = element.size.length > 3 ? element.size[3] : 0;
            const pixWidth = Math.floor((this.width - pixLength) / (this.grids - length) * width);
            const pixHeight = Math.floor(height * this.width / this.grids);
            length += width;
            pixLength += pixWidth;
            return this.drawElement(element, pixWidth, pixHeight, width);
        });

        this.length = 0;
        this.line = [];
        this.weight = [];
        return result;
    }

    startDraw() {
        this.output = [];
        this.line = [];
        this.weight = [];
        this.length = 0;
    }

    finishDraw() {
        if (this.length > 0) this.output.push(this.buildLine());
    }

    pushElement(element) {
        if (!element.size) return;
        let basicSize = this.variant && this.variant === 'compact' ? element.size[0] : element.size[1];
        if (basicSize < 0) basicSize = Math.max(element.size[0], this.grids - this.length);
        if (this.length > 0 && basicSize + this.length > this.grids) this.output.push(this.buildLine());
        let width = element.size[1] < 0 ? (this.grids - this.length) : element.size[1];
        if (width > this.grids) width = Math.max(element.size[0], this.grids);
        this.line.push(element);
        this.weight.push(width);
        this.length += width;
    }

    render() {
        this.startDraw();
        for (const element of this.elements) this.pushElement(element);
        for (const element of this.globalelements) {
            if (element.puuid !== this.uuid) continue;
            this.pushElement(element);
        }
        this.finishDraw();
        return <React.Fragment>{this.output}</React.Fragment>;
    }
}

export default VenusFragment;