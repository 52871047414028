import * as React from 'react';

import Grid from '@mui/material/Grid';

class VenusScript extends React.Component {
    constructor(props) {
        super();
        this.element = props.element;
        this.width = props.width;
        this.height = props.height;
    }

    componentDidMount() {
        eval(this.element.code);
    }

    render() {
        let style = {};
        style.width = this.width + 'px';
        if (this.height > 0) style.height = this.height + 'px';

        return <Grid id={this.element.uuid} key={this.element.uuid} container item style={style}></Grid>;
    }
}

export default VenusScript;