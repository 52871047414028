import AbstractHandler from './AbstractHandler';
import { load_component } from './App';



export class AbstractPage extends AbstractHandler {
    constructor(path) {
        super(path, 'get', {});
        this.resultCode = -10;
    }

    result(result, info) {
    }

    handle(result, info) {
        this.resultCode = result;
        if (result === -1) this.forceUpdate();
        if (result >= 0) this.result(result, info);
    }

    componentDidMount() {
        this.submit();
    }

    draw() {
        return null;
    }

    render() {
        if (this.resultCode < 0 && this.resultCode !== -2) return null;
        if (this.resultCode === -2) return load_component('login');
        return this.draw();
    }
}



export default AbstractPage;