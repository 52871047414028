import * as React from 'react';

import config from '../config.json';
import { loadFeatures, loadPlugins } from '../loader';



const components = loadFeatures();
const plugins = loadPlugins();



export function load(url) {
    window.location.href = url + '.html';
}



export function refresh() {
    window.appframe.setState({ id: window.appframe.state.id + 1 });
}



export async function get_json(path) {
    try {
        const response = await fetch(config.server + path, { credentials: 'include', timeout: 2000 });
        if (!response.ok) return {};
        return await response.json();
    }
    catch (error) {
        return {};
    }
}



export async function post_file(path, file, data) {
    try {
        const form = new FormData();
        if (file.name) form.append("file", file);
        form.append("json", JSON.stringify(data));
        const response = await fetch(config.server + path, { credentials: 'include', method: 'POST', body: form, timeout: 2000 });
        if (!response.ok) return {};
        return await response.json();
    }
    catch (error) {
        return {};
    }
}



export async function post_json(path, data) {
    try {
        const response = await fetch(config.server + path, { credentials: 'include', method: 'POST', body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, timeout: 2000 });
        if (!response.ok) return {};
        return await response.json();
    }
    catch (error) {
        return {};
    }
}



export async function put_json(path, data) {
    try {
        const response = await fetch(config.server + path, { credentials: 'include', method: 'PUT', body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, timeout: 2000 });
        if (!response.ok) return {};
        return await response.json();
    }
    catch (error) {
        return {};
    }
}



export async function delete_json(path) {
    try {
        const response = await fetch(config.server + path, { credentials: 'include', method: 'DELETE', timeout: 2000 });
        if (!response.ok) return {};
        return await response.json();
    }
    catch (error) {
        return {};
    }
}



export function load_component(base, path, reference) {
    const variant = window.config.feature[base];
    if (!variant) return (null);
    const Component = components[base][variant];
    return (<Component key={'component_' + base + '_' + window.appframe.state.id} path={path} reference={reference} />);
}



export function load_plugin(base, variant, information, reference) {
    if (!window.config.plugin[base]) return (null);
    return new plugins[base][variant](variant, information, reference);
}



export function split_path(path) {
    const path_info = path.split('/');
    return [path_info[1], path.substring(path_info[1].length + 1)];
}



class Frame extends React.Component {
    render() {
        return load_component(config.defaultFeature);
    }
}



class App extends React.Component {
    constructor() {
        super();
        this.state = { loaded: false, id: 0 };
        window.appframe = this;
    }

    async load() {
        document.getElementById('sys.static').style.display = 'none';
        config.server = 'https://' + document.getElementById('sys.server').innerHTML;
        window.language = document.getElementById('sys.language').innerHTML;
        window.lan = require('../lan/' + window.language + '/lan.json');
        for (const feature in config.feature) {
            let lan;
            try {
                lan = require('../feature/' + feature + '.' + config.feature[feature] + '/' + window.language + '.json');
            } catch (ex) { continue; }
            window.lan[feature] = lan;
            if (lan.global) {
                lan.global.forEach((item) => {
                    if (!window[item[0]]) window[item[0]] = {};
                    window[item[0]][item[1]] = item[2];
                });
            }
        }
        for (const plugin in config.plugin) {
            let lan;
            try {
                lan = require('../plugin/' + plugin + '/' + window.language + '.json');
            } catch (ex) { continue; }
            window.lan[plugin] = lan;
        }
        window.config = {};
        window.config.feature = config.feature;
        window.config.plugin = config.plugin;
        window.config.lanelem = config.lanelem;
        this.setState({ loaded: true });
    }

    componentDidMount() {
        this.load();
    }

    render() {
        return this.state.loaded ? <div><Frame id={this.state.id} /></div> : null;
    }
}



export default App;